module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Anonine","short_name":"Anonine","start_url":"/","background_color":"#f7f0eb","theme_color":"#29cc75","display":"standalone","icon":"src/assets/img/manifest/icon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
