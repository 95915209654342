/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('babel-polyfill');
require('url-polyfill');
require('@material/layout-grid/dist/mdc.layout-grid.css');
require('./src/assets/scss/bootstrap.custom.min.css');
require('./src/assets/scss/global.scss');

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};

exports.onServiceWorkerUpdateReady = () => (
  window.location.reload(true)
);
