// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-applications-jsx": () => import("./../src/pages/applications.jsx" /* webpackChunkName: "component---src-pages-applications-jsx" */),
  "component---src-pages-applications-android-jsx": () => import("./../src/pages/applications/android.jsx" /* webpackChunkName: "component---src-pages-applications-android-jsx" */),
  "component---src-pages-applications-extension-jsx": () => import("./../src/pages/applications/extension.jsx" /* webpackChunkName: "component---src-pages-applications-extension-jsx" */),
  "component---src-pages-applications-ios-jsx": () => import("./../src/pages/applications/ios.jsx" /* webpackChunkName: "component---src-pages-applications-ios-jsx" */),
  "component---src-pages-applications-linux-jsx": () => import("./../src/pages/applications/linux.jsx" /* webpackChunkName: "component---src-pages-applications-linux-jsx" */),
  "component---src-pages-applications-macos-jsx": () => import("./../src/pages/applications/macos.jsx" /* webpackChunkName: "component---src-pages-applications-macos-jsx" */),
  "component---src-pages-applications-router-jsx": () => import("./../src/pages/applications/router.jsx" /* webpackChunkName: "component---src-pages-applications-router-jsx" */),
  "component---src-pages-applications-windows-jsx": () => import("./../src/pages/applications/windows.jsx" /* webpackChunkName: "component---src-pages-applications-windows-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../src/pages/forgot-password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-network-jsx": () => import("./../src/pages/network.jsx" /* webpackChunkName: "component---src-pages-network-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-service-jsx": () => import("./../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-update-password-jsx": () => import("./../src/pages/update-password.jsx" /* webpackChunkName: "component---src-pages-update-password-jsx" */),
  "component---src-pages-wireguard-jsx": () => import("./../src/pages/wireguard.jsx" /* webpackChunkName: "component---src-pages-wireguard-jsx" */)
}

